import React, { useState, useEffect } from "react";
import { ModuleWrapper } from "../moduleWrapper";
import { SanityImage } from "@components/sanity/sanityImage";
import { motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const variants = {
  inactive: {
    opacity: 0.2,
  },
  active: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const TwoImageFade = ({
  config,
  title,
  description,
  largeImage,
  smallImage,
}) => {
  const [activeImage, setActiveImage] = useState(0);
  const breakpoints = useBreakpoint();

  return (
    <ModuleWrapper {...config}>
      <div
        className="text-center mx-auto px-gutter relative"
        style={{ maxWidth: breakpoints.md ? "75%" : "100%" }}
      >
        <h2 className="text-5xl text-red mb-6">{title}</h2>
        <h3 className="text-base text-dark-grey">{description}</h3>
      </div>
      <div className="hidden md:block relative mt-space-s w-full h-full">
        <div style={{ width: "85%" }} className={`ml-10 relative transition-all ease-in-out duration-300 ${activeImage === 1 ? "cursor-pointer z-0 translate-y-[128px]" : "z-10 translate-y-0"}`}>
          <motion.div
            variants={variants}
            initial="inactive"
            animate={activeImage === 0 ? "active" : "inactive"}
            onClick={() => setActiveImage(0)}
          >
            <SanityImage className="w-full h-full rounded-2xl" image={largeImage} />
          </motion.div>
        </div>
        <div style={{ width: "30%" }} className={`absolute top-0 right-10 h-full transition-all ease-in-out duration-300 ${activeImage === 0 ? "cursor-pointer z-0 translate-y-[128px]" : "z-10 translate-y-0"}`}>
          <motion.div
            variants={variants}
            initial="inactive"
            animate={activeImage === 1 ? "active" : "inactive"}
            onClick={() => setActiveImage(1)}
          >
            <SanityImage className="w-full h-full rounded-2xl" image={smallImage} />
          </motion.div>
        </div>
      </div>
      <div className="md:hidden px-gutter mt-space-s">
        <SanityImage
          image={largeImage}
          className="w-full h-full rounded-2xl "
        />
        <SanityImage
          image={smallImage}
          className="w-full h-full rounded-2xl mt-space-s"
        />
      </div>
    </ModuleWrapper>
  );
};

export default TwoImageFade;
